<template lang="pug">
  div
    slot(name="activator" :toggle="toggle")
      v-btn(small color='primary'
        @click="toggle"
        :disabled="disabled"
        outlined
      ) Remove from acceptance
    v-dialog(v-model="showing" scrollable max-width=350)
      v-card(data-test="c-exchange-body")
        v-card-title(data-test="c-exchange-title") Are you sure you want to remove candidates from acceptance?
        v-card-text
        v-card-actions
          v-spacer
          v-btn(color="error" text @click="toggle") Cancel
          v-btn(color="success" data-test="c-exchange-submit" text @click="remove") Ok
</template>


<script>
  import errorsMixin from '@/mixins/errors.mixin'

  export default {
    inject: ['svc'],
    mixins: [ errorsMixin ],
    props: {
      disabled: Boolean,
      activeItems: Array,
    },
    data: () => ({
      showing: false
    }),
    methods: {
      toggle() {
        this.showing = !this.showing
      },
      async remove() {
        try {
          const ids = this.activeItems.map(item => item.ID);
          await this.svc().removeFromAccept(ids)
          this.$notify({text: 'Remove from acceptance request sended', type: 'success'})
          this.toggle()
        } catch(err) {
          this.processError(err)
        }
      }
    }
  }
</script>